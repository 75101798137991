<template lang="pug">
  .statically-privacy-en
    h1
      | {{ $t('shared.menus.privacy') }}

    p.
      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
      labore et dolore magna aliqua. Sed libero enim sed faucibus turpis in. Posuere urna nec
      tincidunt praesent. Ut sem viverra aliquet eget sit amet tellus cras. At lectus urna duis
      convallis convallis tellus id interdum velit. Morbi tristique senectus et netus et malesuada
      fames ac. Nisi scelerisque eu ultrices vitae auctor eu augue. Sed viverra tellus in hac
      habitasse platea dictumst vestibulum rhoncus. Arcu vitae elementum curabitur vitae nunc sed.

    p.
      Tincidunt eget nullam non nisi. Sapien nec sagittis aliquam malesuada bibendum arcu vitae
      elementum curabitur. Magna fermentum iaculis eu non diam phasellus. Sed cras ornare arcu dui
      vivamus. Tristique risus nec feugiat in. Purus sit amet luctus venenatis lectus magna
      fringilla urna. Et netus et malesuada fames ac. Et malesuada fames ac turpis egestas.
      Lorem ipsum dolor sit amet consectetur adipiscing. Condimentum id venenatis a condimentum
      vitae. Vulputate odio ut enim blandit volutpat maecenas volutpat blandit aliquam.
      In massa tempor nec feugiat nisl pretium. Commodo odio aenean sed adipiscing.

    p.
      Porttitor massa id neque aliquam vestibulum morbi blandit. Montes nascetur ridiculus mus
      mauris vitae ultricies leo integer malesuada. Congue eu consequat ac felis donec et odio
      pellentesque. Enim nec dui nunc mattis enim. Id porta nibh venenatis cras sed felis eget
      velit. Neque vitae tempus quam pellentesque nec. Potenti nullam ac tortor vitae purus
      faucibus ornare. Leo urna molestie at elementum eu. Neque laoreet suspendisse interdum
      consectetur. Pellentesque elit ullamcorper dignissim cras. Morbi tincidunt ornare massa
      eget egestas. In metus vulputate eu scelerisque felis. Justo nec ultrices dui sapien eget
      mi. Lorem ipsum dolor sit amet consectetur adipiscing. Turpis egestas maecenas pharetra
      convallis posuere morbi leo urna. Mattis nunc sed blandit libero volutpat sed cras. Duis ut
      diam quam nulla porttitor massa id.
</template>

<script>
export default {
  name: 'statically-privacy-en',
}
</script>
